import React from 'react'
import {logo} from '../../assets/images/imageComponents';

const SuspendedScreen = () => {
  return (
    <div className='vh-100 vw-100 d-flex flex-column align-items-center justify-content-center'>
        <img src={logo} alt="swiftwaze logo" className='' />
        <p>Loading ...</p>
    </div>
  )
}

export default SuspendedScreen