import { NavLink } from "react-router-dom";
import { avatar, blog_post_img } from "../../assets/images/imageComponents";

const BlogPost = ({ id }) => {
  return (
    <div className="p-2">
      <NavLink className="text-decoration-none" to={`/blog/${id}`}>
        <img className="w-100 our_mission" src={blog_post_img} alt=""/>
        <h5 className="my-4">Lorem Ipsum is simply dummy text of the printing.</h5>
        <p className="home_text">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
          standard dummy text ever since the .
        </p>
        <div className="d-flex align-items-center mt-4">
          <img src={avatar} alt="" />
          <div className="d-flex w-100 ms-3 align-items-center justify-content-between">
            <div className="me-4">
              <h6 className="mb-0">Name here</h6>
              <p className="mb-0 home_text">20.12.2020</p>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default BlogPost;
