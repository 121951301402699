import IconsComponent from '../../../assets/icons/iconsComponent';
import ChartComponent from '../../../shared/components/chartComponent';
import DashboardRightPanel from '../../../shared/components/dashboard/DashboardRightPanel';
import FloatInputField from '../../../shared/components/floatInputField';
import WalletUserProfile from '../../../shared/components/userProfile/wallet/walletUserProfile';
import DashboardLayout from '../../../shared/layouts/dashboardLayout';

const Wallet = () => {
	return (
		<>
			<DashboardLayout>
				<div className="scroll_none">
					<div className="row">
						<div className="col-lg-8 col-xl-9 scroll_y vh-100">
							<div className="container-fluid">
								<div className="py-4">
									<div className="d-flex block justify-content-between">
										<h3 className="dashboard_header">My Wallet</h3>
									</div>
									<div className="row mt-5">
										{[1, 2, 3, 4].map((wallet) => (
											<div className="col-12 col-md-6 mb-4 col-xl-3">
												<div className="shadow p-3">
													<h6 className="text-muted">Naria Wallet Balance</h6>
													<h3 className="balance_header">₦ 409,063</h3>
												</div>
											</div>
										))}
									</div>
									<div className="mt-5 d-flex justify-content-between mb-4">
										<div className="d-flex align-items-center block">
											<button className="bg-transparent border-0 px- me-3">Wallet</button>
											<button className="bg-transparent border-0 px-">Card Information</button>
										</div>
										<div className="btn-group">
											<button
												type="button"
												className="bg-white border px-4 py-2 dropdown-toggle"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												Duration
											</button>
											<ul className="dropdown-menu dropdown-menu-end">
												<li>
													<button className="dropdown-item" type="button">
														Daily
													</button>
												</li>
												<li>
													<button className="dropdown-item" type="button">
														Monthly
													</button>
												</li>
											</ul>
										</div>
									</div>
									<ChartComponent />
									<div className="col-12 mt-5">
										<h4>My Personalized Accounts</h4>
										<div className="accordion" id="accordionExample">
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingOne">
													<button
														className="accordion-button"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseOne"
														aria-expanded="true"
														aria-controls="collapseOne"
													>
														<span className="naira_bg d-flex align-items-center justify-content-center">
															<IconsComponent props="naira" />
														</span>
														<span className="balance_detail_header ms-3">Naira Account</span>
													</button>
												</h2>
												<div
													id="collapseOne"
													className="accordion-collapse collapse show"
													aria-labelledby="headingOne"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body scroll_x py-4">
														<div className="d-flex align-items-center justify-content-between">
															<h3 className=" mb-0 account_detail_body">0078354627</h3>
															<p className="account_detail_body mx-4 mb-0">Swiftwaze(Christopher Aniedi)</p>
															<p className="account_detail_body mb-0">Wema Bank</p>
															<div className="d-flex mx-4 align-items-center">
																<button className="bg-transparent account_detail_body me-3 border rounded px-3">
																	copy
																</button>
																<button className="bg-transparent account_detail_body me-4 border rounded px-3">
																	share
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="accordion-item">
												<h2 className="accordion-header" id="headingTwo">
													<button
														className="accordion-button"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseTwo"
														aria-expanded="true"
														aria-controls="collapseTwo"
													>
														<span className="naira_bg d-flex align-items-center justify-content-center">
															<IconsComponent props="naira" />
														</span>
														<span className="balance_detail_header ms-3">USD Dollar Account</span>
													</button>
												</h2>
												<div
													id="collapseTwo"
													className="accordion-collapse collapse"
													aria-labelledby="headingTwo"
													data-bs-parent="#accordionExample"
												>
													<div className="accordion-body scroll_x py-4">
														<div className="d-flex align-items-center justify-content-between">
															<h3 className=" mb-0 account_detail_body">0078354627</h3>
															<p className="account_detail_body mx-4 mb-0">Swiftwaze(Christopher Aniedi)</p>
															<p className="account_detail_body mb-0">Wema Bank</p>
															<div className="d-flex mx-4 align-items-center">
																<button className="bg-transparent account_detail_body me-3 border rounded px-3">
																	copy
																</button>
																<button className="bg-transparent account_detail_body me-4 border rounded px-3">
																	share
																</button>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 xs_hide sm_hide md_hide col-xl-3 ps-0 scroll_y vh-100">
							<DashboardRightPanel>
								<WalletUserProfile />
							</DashboardRightPanel>
						</div>
					</div>
				</div>
			</DashboardLayout>
		</>
	);
};

export default Wallet;
