import { Checkbox, DatePicker, Space } from "antd";
import FloatInputField from "../../../../shared/components/floatInputField";

const CardSection = () => {
  //   const [value, setValue] = useState;

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
  };
  return (
    <>
      <div className="col-12">
        <FloatInputField
          props={{
            label: "Card Number",
            type: "number",
            style: "w-100 input_style rounded border",
          }}
        />
      </div>
      <div className="col-6 mt-4">
        <Space direction="vertical">
          <DatePicker
            className="input_style border rounded"
            // onChange={onChange}
            placeholder="Expiry Date"
          />
        </Space>
      </div>
      <div className="col-6 mt-4">
        <FloatInputField
          props={{
            label: "CVV",
            type: "number",
            style: "w-100 input_style rounded border",
          }}
        />
      </div>
      <div className="col-12 mt-4">
        <Checkbox onChange={onChange}>Save this card</Checkbox>
      </div>
    </>
  );
};

export default CardSection;
