import React from 'react';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../shared/components/buttonComponent';

const Error404 = () => {
	return (
		<div className="d-flex flex-col align-items-center justify-content-center vh-100 page_404">
			<div className="text-center ">
				<h1>404</h1>
				<p>The page you’re looking for doesn’t exist.</p>
				<Link to="/">
					<ButtonComponent
						props={{
							anchor: 'Go Home',
							style: 'onboarding_btn w-50 text-uppercase f-14 mt-4',
						}}
					/>
				</Link>
			</div>
		</div>
	);
};

export default Error404;
