import { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import DashboardLayout from '../../../shared/layouts/dashboardLayout';
import TableComponent from '../../../shared/components/tableComponent';
import IconsComponent from '../../../assets/icons/iconsComponent';
import ChartComponent from '../../../shared/components/chartComponent';
import TransferMoney from './transferMoney/transferMoney';
import XIcon from '../../../assets/icons/x_close.svg';
import LeftArrow from '../../../assets/icons/left_arrow.svg';
import PayBills from './payBill/paybill';
import Airtime from './airtime/airtime';
import TopUp from './topUp/topUp';
import RequestPayout from './requestPayout/requestPayout';
import UserComponent from '../../../shared/components/userProfile/userComponent';
import DashboardUserProfile from '../../../shared/components/userProfile/dashboard/dashboardUserProfile';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Data from './data/Data';
import { useDispatch } from 'react-redux';
import { fetchTopUpList } from '../../../shared/redux/transactionSlice';
import DashboardRightPanel from '../../../shared/components/dashboard/DashboardRightPanel';
import { toFormData } from '../../../shared/helpers/authHelpers';

const Dashboard = () => {
	const [visible, setVisible] = useState(false);
	const [show, setShow] = useState();
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const { topups, loadList } = useSelector((state) => state.transaction);
	const [data, setData] = useState({
		page: 1,
		per_page: 10,
		transaction_type: 'topup',
	});

	const onClose = () => {
		setVisible(!visible);
	};

	useEffect(() => {
		dispatch(fetchTopUpList(toFormData(data)));
	}, []);
	return (
		<DashboardLayout>
			<div className="scroll_none container-fluid">
				<div className="row">
					<div className="col-lg-9 scroll_y vh-100">
						<div className="container-fluid">
							<div className="py-4">
								<h6 className="dashboard_date">{moment().format('dddd, MMMM Do YYYY')} </h6>
								<h3 className="dashboard_header">Hello, {`${user?.fname} ${user?.lname}`}</h3>

								<div className="d-flex align-items-center scroll_x justify-content-between shadow mt-4 p-4 bg-white rounded">
									<button
										onClick={() => {
											setShow('send-money');
											setVisible(true);
										}}
										className="d-flex flex-column border-0 bg-transparent justify-content-center"
									>
										<span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
											<IconsComponent props="send-icon" />
										</span>
										<p className="mb-0 mt-3 dashboard_actions">Send Money</p>
									</button>
									<button
										onClick={() => {
											setShow('pay-bills');
											setVisible(true);
										}}
										className="d-flex mx-4 flex-column border-0 bg-transparent justify-content-center"
									>
										<span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
											<IconsComponent props="paybills-icon" />
										</span>
										<p className="mb-0 mt-3 dashboard_actions">Pay Bills</p>
									</button>
									<button
										onClick={() => {
											setShow('top-up');
											setVisible(true);
										}}
										className="d-flex flex-column border-0 bg-transparent justify-content-center"
									>
										<span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
											<IconsComponent props="topup-icon" />
										</span>
										<p className="mb-0 mt-3 dashboard_actions">Top Up</p>
									</button>
									<button
										onClick={() => {
											setShow('airtime');
											setVisible(true);
										}}
										className="d-flex mx-4 flex-column border-0 bg-transparent justify-content-center"
									>
										<span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
											<IconsComponent props="airtime-icon" />
										</span>
										<p className="mb-0 mt-3 dashboard_actions">Airtime</p>
									</button>
									<button
										onClick={() => {
											setShow('data');
											setVisible(true);
										}}
										className="d-flex mx-4 flex-column border-0 bg-transparent justify-content-center"
									>
										<span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
											<IconsComponent props="data-icon" />
										</span>
										<p className="mb-0 mt-3 dashboard_actions">Data</p>
									</button>
									<button
										onClick={() => {
											setShow('request-payout');
											setVisible(true);
										}}
										className="d-flex flex-column border-0 bg-transparent justify-content-center"
									>
										<span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
											<IconsComponent props="request-payment-icon" />
										</span>
										<p className="mb-0 mt-3 dashboard_actions">Request Payment</p>
									</button>
									{/* <button className="d-flex flex-column border-0 bg-transparent justify-content-center">
                    <span className="dashboard_action_bg mx-auto d-flex align-items-center justify-content-center">
                      <IconsComponent props="more-icon" />
                    </span>
                    <p className="mb-0 mt-3 dashboard_actions">More</p>
                  </button> */}
								</div>
								<div className="mt-5 d-flex justify-content-between mb-4">
									<div className="d-flex align-items-center block">
										<button className="bg-transparent border-0 px- me-3">Wallet</button>
										<button className="bg-transparent border-0 px-">Card Information</button>
									</div>
									<div className="btn-group">
										<button
											type="button"
											className="bg-white border px-4 py-2 dropdown-toggle"
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											Duration
										</button>
										<ul className="dropdown-menu dropdown-menu-end">
											<li>
												<button className="dropdown-item" type="button">
													Daily
												</button>
											</li>
											<li>
												<button className="dropdown-item" type="button">
													Monthly
												</button>
											</li>
										</ul>
									</div>
								</div>
								<div className="my-4 mt-4">
									<ChartComponent />
								</div>
								<div className="table-responsive">
									<TableComponent type="dashboard" transactions={topups} loading={loadList} />
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 xs_hide sm_hide md_hide ps-0 scroll_y vh-100">
						<DashboardRightPanel>
							<DashboardUserProfile />
						</DashboardRightPanel>
					</div>
				</div>
			</div>

			{/* Drawer */}
			<Drawer closable={true} placement="right" width={430} onClose={onClose} visible={visible}>
				{/* <img src={XIcon} alt="close" onClick={()=>se} /> */}

				{show === 'send money' && <TransferMoney />}
				{show === 'pay-bills' && <PayBills />}
				{show === 'airtime' && <Airtime />}
				{show === 'data' && <Data />}
				{show === 'top-up' && <TopUp />}
				{show === 'request-payout' && <RequestPayout />}
			</Drawer>
		</DashboardLayout>
	);
};

export default Dashboard;
