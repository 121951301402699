import { useState, useEffect } from 'react';
import { Select, Radio } from 'antd';
import PhoneInput from 'react-phone-input-2';
import ButtonComponent from '../../../../shared/components/buttonComponent';
import TransferSuccess from '../transferMoney/transferSuccess';
import FundingSource from '../../../../shared/components/payment/FundingSource';
import TransactionPin from '../../../../shared/components/payment/TransactionPin';
import { axiosPrivate } from '../../../../shared/helpers/axios/axios-private';
import { useSnackbar } from 'notistack';
import { toFormData } from '../../../../shared/helpers/authHelpers';
import LeftArrow from '../../../../assets/icons/left_arrow.svg';

const handleChange = (value) => {
	console.log(`selected ${value}`);
};

const { Option } = Select;

const Data = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [phone, setPhone] = useState('');
	const [operators, setOperators] = useState([]);
	const [selectedOperator, setSelectedOperator] = useState(null);
	const [selectedData, setSelectedData] = useState(null);
	const [beneficiaries, setBeneficiaries] = useState([]);
	const [loadingOperators, setLoadingOperators] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [screen, setScreen] = useState(1);
	const [otp, setOtp] = useState('');
	const [value, setValue] = useState('new beneficiary');
	const [dataProducts, setDataProducts] = useState([]);
	const [errorMsg, setErrorMsg] = useState({
		phone: '',
		operator: '',
		amount: '',
	});

	const isDisabled = () => {
		if (!phone || !selectedOperator || !selectedData) {
			return true;
		}
		return false;
	};

	const onChange = (e) => {
		setValue(e.target.value);
	};

	const clearData = () => {
		setPhone('');
		setSelectedOperator(null);
		setSelectedData(null);
		setScreen(1);
	};

	const handleFields = (e) => {
		e.preventDefault();
		e.preventDefault();
		if (!phone) {
			setErrorMsg((prev) => ({ ...prev, phone: 'Phone number is required' }));
			return;
		} else if (!selectedOperator) {
			setErrorMsg((prev) => ({ ...prev, operator: 'Operator is required' }));
			return;
		} else if (!selectedData) {
			setErrorMsg((prev) => ({ ...prev, amount: 'Amount is required' }));
			return;
		} else {
			setScreen(2);
		}
	};

	const fetchOperators = async (phone) => {
		setLoadingOperators(true);
		try {
			const res = await axiosPrivate.post(`/topup/operatorslookup`, toFormData({ phone: `+${phone}` }));

			if (res?.data?.status) {
				setOperators(res?.data?.data);
			} else {
				setErrorMsg(res?.data?.msg);
			}
			setLoadingOperators(false);
		} catch (error) {
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
			setLoadingOperators(false);
		}
	};

	const fetchDataProducts = async (id) => {
		setLoadingData(true);

		try {
			const res = await axiosPrivate.post(`/topup/dataproducts`, toFormData({ operator_id: id }));

			if (res?.data?.status) {
				setDataProducts(res?.data);
			} else {
				setErrorMsg((prev) => ({ ...prev, operator: res?.data?.msg }));
			}
			setLoadingData(false);
		} catch (error) {
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
			setLoadingData(false);
		}
	};

	const buyData = async (e) => {
		e.preventDefault();
		setLoadingOperators(true);
		let payload = {
			product_id: selectedData?.value,
			receiver_mobile_number: `+${phone}`,
			payment_method_id: 1,
			transaction_pin: otp,
		};
		try {
			const res = await axiosPrivate.post(`/topup/transactions`, toFormData(payload));

			if (res?.data?.status) {
				setScreen(3);
				setOtp('');
			} else {
				enqueueSnackbar(res.data.msg, {
					variant: 'error',
				});
			}
			setLoadingOperators(false);
		} catch (error) {
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
			setLoadingOperators(false);
		}
	};

	useEffect(() => {
		let getOperators;

		if (phone?.length >= 10) {
			getOperators = setTimeout(() => {
				fetchOperators(phone);
			}, 1000);
		}
		return () => {
			clearTimeout(getOperators);
		};
	}, [phone]);

	useEffect(() => {
		if (selectedOperator) {
			fetchDataProducts(selectedOperator);
		}
	}, [selectedOperator]);

	return (
		<div className="container drawer_container">
			{screen === 1 && (
				<>
					<h3 className="drawer_header">Data</h3>
					<p className="drawer_subheader"></p>
					<div className="mt-3 h-100">
						<Select className="wallet_select" defaultValue="ngn" onChange={handleChange}>
							<Option value="ngn">NGN Wallet Balance</Option>
							<Option value="usd">Dollar Wallet Balance</Option>
						</Select>
						<h3 className="balance_header">₦ 3,041,730</h3>
						<form onSubmit={handleFields} className="row d-flex flex-column justify-content-between h-100">
							<div>
								<>
									<div className="col-12 mt-4 mb-2 d-flex justify-content-start">
										<Radio.Group className="d-flex" onChange={onChange} value={value}>
											<Radio value="new beneficiary">New Beneficiary</Radio>
											<Radio value="save beneficiary">Saved Beneficiary</Radio>
										</Radio.Group>
									</div>
									{value === 'new beneficiary' ? (
										<div className="col-12 mt-2 mb-2">
											<label className=" text_secondary" htmlFor="pword">
												Phone Number
											</label>
											<PhoneInput
												inputExtraProps={{
													required: true,
													min: 11,
												}}
												value={phone}
												onChange={(phone) => {
													setPhone(phone);
													setErrorMsg({ ...errorMsg, phone: '' });
												}}
												country={'ng'}
												placeholder=""
												countryCodeEditable={false}
												enableSearch={true}
												disableSearchIcon={true}
												inputClass={`w-100 input_style border rounded phone_input`}
											/>
										</div>
									) : (
										<div className="col-12 mt-1">
											<label className="mb-2 text_secondary" htmlFor="country_id">
												Select Beneficiary
											</label>

											<Select
												showSearch
												bordered={false}
												optionFilterProp="children"
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												className="w-100 input_style border rounded"
												options={beneficiaries?.map((beneficiary) => {
													return { label: beneficiary?.name, value: beneficiary?.id };
												})}
											/>
										</div>
									)}
									{errorMsg.phone && <p className="text-danger f-10">{errorMsg?.phone}</p>}
								</>

								<div className="col-12 my-3">
									<label className="mb-1 text_secondary" htmlFor="country_id">
										Select Network {loadingOperators && <span className="spinner-border spinner-border-sm"></span>}
									</label>
									<Select
										showSearch
										bordered={false}
										placeholder="Select Network"
										optionFilterProp="children"
										disabled={operators?.length < 1}
										value={selectedOperator}
										onChange={(value) => {
											setSelectedOperator(value);
											setErrorMsg({ ...errorMsg, operator: '' });
										}}
										filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
										className="w-100 input_style border rounded"
										options={operators?.map((operator) => {
											return { label: operator?.operator_name, value: operator?.operator_id };
										})}
									/>
									{errorMsg.operator && <p className="text-danger f-10 mt-2">{errorMsg?.operator}</p>}
								</div>

								<>
									<div className="col-12 mt-3">
										<label className="mb-1 text_secondary" htmlFor="pword">
											Amount {loadingData && <span className="spinner-border spinner-border-sm"></span>}
										</label>
										<Select
											showSearch
											bordered={false}
											placeholder="Select Amount"
											optionFilterProp="children"
											disabled={dataProducts?.length < 1}
											value={selectedData?.value}
											onChange={(value, label) => {
												setSelectedData(label);
												setErrorMsg({ ...errorMsg, amount: '' });
											}}
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											className="w-100 input_style border rounded"
											options={dataProducts?.map((product) => {
												return { label: product?.name, value: product?.id };
											})}
										/>
										{errorMsg.amount && <p className="text-danger f-10 mt-2">{errorMsg?.amount}</p>}
										{/* <div className="airtime_amount data_container">
											{dataProducts?.map((product) => {
												return (
													<div
														key={product?.id}
														className={`cursor ${selectedData?.id === product?.id ? 'selected' : ''}`}
														onClick={() => setSelectedData(product)}
													>
														<span className="data_name">{product?.name}</span>
														<span>{product?.description}</span>
														<span>{product?.pricing?.currency + ' ' + product?.pricing?.amount}</span>
													</div>
												);
											})}
										</div> */}
									</div>

									<div className="mt-2">
										<FundingSource />
									</div>
								</>
							</div>
							<div className="col-12 mt-5 drawer_button">
								<ButtonComponent
									props={{
										anchor: 'proceed',
										style: 'onboarding_btn w-100 text-uppercase',
										// disabled: isDisabled(),
									}}
								/>
							</div>
						</form>
					</div>
				</>
			)}
			{screen === 2 && (
				<>
					<TransactionPin
						loading={loadingOperators}
						setOtp={setOtp}
						otp={otp}
						handleTransaction={buyData}
						setScreen={setScreen}
						buttonText="Buy Data"
					/>
				</>
			)}
			{screen === 3 && (
				<>
					<TransferSuccess
						message={`You have successfully credited ${phone} with ${selectedData?.label} from your NGN Wallet`}
						buttonAction={clearData}
					/>
				</>
			)}
		</div>
	);
};

export default Data;
