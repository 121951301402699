import React, { useState } from 'react';
import { fetchTransaction } from '../redux/transactionSlice';
import { useDispatch } from 'react-redux';
import { setVisibility } from '../redux/transactionSlice';
import { Skeleton } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

const TableComponent = ({ transactions, type, loading, setData }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [pageOffset, setPageOffset] = useState(0);
	const itemsPerPage = 8;
	const pageCount = Math.ceil(transactions?.total / itemsPerPage);

	const handleTransaction = (id) => {
		if (type === 'dashboard') {
			navigate('/transactions');
		}
		dispatch(setVisibility(true));
		dispatch(fetchTransaction({ id, type }));
	};

	const handlePageClick = (event) => {
		console.log(event);
		setData((data) => ({ ...data, page: event.selected + 1 }));
		setPageOffset(((event.selected + 1) * itemsPerPage) % transactions?.total);
	};

	return (
		<div>
			<div className="table-responsive">
				<table className="table">
					<thead className="">
						<tr>
							<th scope="col">Amount</th>
							<th scope="col">Reference</th>
							<th scope="col">Date & Time</th>
							<th scope="col">Type </th>
							<th scope="col">Status</th>
						</tr>
					</thead>
					{loading ? (
						<tbody>
							<tr>
								{[...Array(5)].map((_, index) => (
									<td key={index}>
										<Skeleton active />
									</td>
								))}
							</tr>
						</tbody>
					) : (
						<tbody>
							{type !== 'dashboard' &&
								transactions?.data?.map((transaction) => (
									<tr key={transaction?.id} className="cursor" onClick={() => handleTransaction(transaction?.id)}>
										<td>{transaction?.amount}</td>
										<td>{transaction?.reference}</td>
										<td>{moment(transaction?.trans_date).format('DD/MM/YY, HH:mm a')}</td>
										<td>{transaction?.type}</td>
										<td>
											<span className="badge bg-success bg-opacity-75" style={{ fontSize: '11px' }}>
												{transaction?.transaction_status}
											</span>
										</td>
									</tr>
								))}
							{type === 'dashboard' &&
								transactions?.data?.slice(0, 5).map((transaction) => (
									<tr key={transaction?.id} className="cursor" onClick={() => handleTransaction(transaction?.id)}>
										<td>{transaction?.amount}</td>
										<td>{transaction?.reference}</td>
										<td>{moment(transaction?.trans_date).format('DD/MM/YY, HH:mm a')}</td>
										<td>{transaction?.type}</td>
										<td>
											<span className="badge bg-success bg-opacity-75" style={{ fontSize: '11px' }}>
												{transaction?.transaction_status}
											</span>
										</td>
									</tr>
								))}
						</tbody>
					)}
				</table>
			</div>
			{/* {!transactions?.data && (
				<div className="text-center text-muted mt-4">
					<h4>No more item</h4>
				</div>
			)} */}

			{/* Navigation */}
			{type !== 'dashboard' && (
				<div className="d-flex justify-content-end align-items-center gap-2 mt-4 pagination">
					<ReactPaginate
						previousLabel="<<"
						nextLabel=">>"
						pageClassName="page-item"
						pageLinkClassName="page-link"
						previousClassName="page-item"
						previousLinkClassName="page-link"
						nextClassName="page-item"
						nextLinkClassName="page-link"
						breakLabel="..."
						breakClassName="page-item"
						breakLinkClassName="page-link"
						pageCount={pageCount}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={handlePageClick}
						containerClassName="pagination"
						activeClassName="active"
						// forcePage={pageOffset}
						renderOnZeroPageCount={null}
					/>
				</div>
			)}
		</div>
	);
};

export default TableComponent;
