import { NavLink, useNavigate } from 'react-router-dom';
import { logo } from '../../../assets/images/imageComponents';
import { useMutation } from 'react-query';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import IconsComponent from '../../../assets/icons/iconsComponent';
import ButtonComponent from '../../components/buttonComponent';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

const navLinks = [
	{
		name: 'Dashboard',
		icon: 'dashboard',
		path: '/dashboard',
	},
	{
		name: 'Transactions',
		icon: 'transaction-icon',
		path: '/transactions',
	},
	{
		name: 'Wallets',
		icon: 'wallet-icon',
		path: '/wallets',
	},
	{
		name: 'My Profile',
		icon: 'profile-icon',
		path: '/profile',
	},
];

const LeftSide = () => {
	const { pathname } = useLocation();
	const { enqueueSnackbar } = useSnackbar();
	const mutation = useMutation(() => {
		return axios.get(`/logout`);
	});
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
	};
	const handleShow = () => setShow(true);

	const handleLogout = async () => {
		try {
			const res = await mutation.mutateAsync();
			let data = res.data;

			enqueueSnackbar(data?.msg, {
				variant: data?.status ? 'success' : 'error',
			});

			if (data?.status) {
				localStorage.removeItem('swiftwaze_tokens');
				navigate('/sign-in');
			}
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	};

	return (
		<>
			<div className="d-flex flex-column">
				<div className="text-center mt-4 mb-4">
					<img src={logo} width="50px" alt="" />
				</div>
				<ul className="list-unstyled d-flex flex-column leftside mt-4">
					{navLinks.map((link, index) => {
						return (
							<li key={index} className={`text-center p-3 ${pathname?.includes(link.path) && 'nav-active'}`}>
								<NavLink className="text-decoration-none" to={link.path}>
									<IconsComponent props={link.icon} active={pathname?.includes(link.path) ? true : false} />
									<p className="sidebar_links mt-2 mb-0">{link.name}</p>
								</NavLink>
							</li>
						);
					})}
					<li className="text-center p-4 click cursor" onClick={handleShow}>
						<IconsComponent props="logout-icon" />
						<p className="sidebar_links mt-3 mb-0">Logout</p>
					</li>
				</ul>
			</div>

			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
				<div className="container">
					<Modal.Header className="border-0" closeButton>
						<Modal.Title className="text-danger text-center text-uppercase">Logout</Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center f-14">
						Are you sure you want to <br /> logout of your account?
					</Modal.Body>
					<Modal.Footer className="border-0 my-4">
						<ButtonComponent
							props={{
								anchor: 'Yes, Logout',
								isLoading: mutation.isLoading,
								style: 'onboarding_btn w-100 text-uppercase f-12',
								handleClick: handleLogout,
							}}
						/>
					</Modal.Footer>
				</div>
			</Modal>
		</>
	);
};

export default LeftSide;
