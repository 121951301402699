import { Checkbox, DatePicker, Space } from 'antd';
import FloatInputField from '../floatInputField';

const CardSection = () => {
	//   const [value, setValue] = useState;

	const onChange = (e) => {
		console.log('radio checked', e.target.value);
	};
	return (
		<>
			<div className="col-12 mb-2 mt-4">
				<input type="text" placeholder="Name on Card" className="w-100 input_style border rounded" />
			</div>
			<div className="col-12 mb-2">
				<input type="number" placeholder="Card Number" className="w-100 input_style border rounded" />
			</div>
			<div className="row">
				<div className="col-6">
					<Space direction="vertical">
						<DatePicker
							className="w-100 input_style border rounded"
							// onChange={onChange}
							placeholder="Expiry Date"
							picker="month"
						/>
					</Space>
				</div>
				<div className="col-6">
					<input type="number" placeholder="CVV" maxLength={3} className="w-100 input_style border rounded" />
				</div>
			</div>
			<div className="col-12 mt-3">
				<Checkbox onChange={onChange}>Save this card</Checkbox>
			</div>
		</>
	);
};

export default CardSection;
