import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

const FloatInputField = ({ props }) => {
  // const [value, setValue] = useState();
  const cities = [
    { name: "Option A" },
    { name: "Option B" },
    { name: "Option C" },
  ];

  switch (props.type) {
    case "text":
      return (
        <>
          <div className="field">
            <span className="p-float-label">
              <input
                type={props.type}
                className={props.style && props.style}
              />
              <label htmlFor={props.label && props.label}>
                {props.label && props.label}
              </label>
            </span>
          </div>
        </>
      );
      break;

    case "search":
      return (
        <>
          <span className="p-float-label p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
            />
            <label htmlFor={props.label && props.label}>
              {props.label && props.label}
            </label>
          </span>
        </>
      );
      break;

    case "dropdown":
      return (
        <>
          <span className="p-float-label">
            <Dropdown
              className={props.style && props.style}
              options={cities}
              optionLabel="name"
            />
            <label htmlFor={props.label && props.label}>
              {props.label && props.label}
            </label>
          </span>
          {/* <div className="field">
            <span className="p-float-label">
              <div className="btn-group w-100">
                <button
                  type="button"
                  className={`bg-white px-4 dropdown-toggle ${
                    props.style && props.style
                  }`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Select Bank
                </button>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <button className="dropdown-item" type="button">
                      UBA
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item" type="button">
                      Zenith
                    </button>
                  </li>
                </ul>
              </div>
            </span>
          </div> */}
        </>
      );
      break;

    case "number":
      return (
        <>
          <div className="field">
            <span className="p-float-label">
              <input
                type={props.type}
                className={props.style && props.style}
              />
              <label htmlFor={props.label && props.label}>
                {props.label && props.label}
              </label>
            </span>
          </div>
        </>
      );
      break;

    case "textarea":
      return (
        <>
          <div className="field">
            <span className="p-float-label">
              <textarea
                className={props.style && props.style}
                name=""
                id=""
                cols="30"
                rows="5"
              ></textarea>
              <label htmlFor={props.label && props.label}>
                {props.label && props.label}
              </label>
            </span>
          </div>
        </>
      );
      break;

    default:
      break;
  }
};

export default FloatInputField;
