import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../helpers/axios/axios-private';

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
	const response = await axiosPrivate.get('/checker');
	return response?.data?.data;
});

const authSLice = createSlice({
	name: 'user',
	initialState: {
		token: null,
		user: null,
		loading: false,
	},
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setUser: (state, action) => {
			state.user = action.payload;
			
		},
		logout: (state) => {
			state.token = null;
			state.user = null;
			localStorage.removeItem('swiftwaze_tokens');
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchUser.fulfilled, (state, { payload }) => {
				state.user = payload;
				state.loading = false;
			});
	},
});

export const { setToken, setUser, logout } = authSLice.actions;
export default authSLice.reducer;
