import React from 'react'

const SwiftWallet = () => {
  return (
		<div className="saved_card d-flex align-items-center justify-content-between">
			<div className="d-flex align-items-center">
				<div className="dot"></div>
				<div className="ms-3">
					<h5 className="mb-0">Wallet Balance</h5>
					<p className="mb-0">N4,673,906.43</p>
				</div>
			</div>
		</div>
	);
}

export default SwiftWallet