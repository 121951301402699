import axios from 'axios';
import { memoizedRefreshToken } from '../authHelpers';

let originalRequest;

axios.defaults.baseURL = process.env.REACT_APP_DEMO_URL;
axios.defaults.headers['Content-Type'] = '"application/x-www-form-urlencoded; charset=UTF-8"';

axios.interceptors.request.use(
	(config) => {
		const token = JSON.parse(localStorage.getItem('swiftwaze_tokens'));
		if (token?.access_token) {
			config.headers.Authorization = `Bearer ${token?.access_token}`;
		}
		originalRequest = config;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

const sleepRequest = (milliseconds, originalRequest) => {
	return new Promise((resolve, reject) => {
		setTimeout(() => resolve(axios(originalRequest)), milliseconds);
	});
};

axios.interceptors.response.use(
	async (response) => {
		if (response?.data?.status === false && response?.data?.data?.session_active === false) {
			// console.log(response?.data, 'response-res');
			const res = await memoizedRefreshToken();
			if (res?.data?.status) {
				// window.location.reload();
				return sleepRequest(1000, originalRequest);
			}else{
				localStorage.removeItem('swiftwaze_tokens');
				window.location.reload();
			}

			// console.log(res, 'reeeeee');
		} else {
			return response;
		}
	},
	(error) => {
		return Promise.reject(error);
	}
);

export const axiosPrivate = axios;
