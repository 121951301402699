import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import OnboardingLayout from '../../../shared/layouts/onboardingLayout';
import OtpInput from 'react-otp-input';
import { useState } from 'react';
import { toFormData } from '../../../shared/helpers/authHelpers';
import { axiosPublic } from '../../../shared/helpers/axios/axios-public';

const PasswordReset = () => {
	const [otp, setOtp] = useState('');
	const location = useLocation();
	const navigate = useNavigate();

	const handleOTP = (otp) => {
		setOtp(otp);
		console.log(otp, 'otp');
	};

	const { enqueueSnackbar } = useSnackbar();

	const mutation = useMutation((formData) => {
		return axiosPublic.post(`/resetpass`, formData);
	});

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const res = await mutation.mutateAsync(toFormData({ token: otp, email: location.state?.email }));
			let data = res?.data;

			enqueueSnackbar(data?.msg, {
				variant: data?.status ? 'success' : 'error',
			});

			data?.status && navigate('/change-password', { state: { email: location.state?.email } });
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	};

	useEffect(() => {
		if (!location.state?.email) {
			navigate('/forgot-password');
		}
	}, []);

	return (
		<OnboardingLayout>
			<div className="onboarding_scroll_y">
				<div className="w-100">
					<div className="text-center mb-4">
						<h3 className="fw-bold">Reset Password</h3>
						<p className="home_text text-muted">Enter the one-time-password sent to your email</p>
					</div>
					<form onSubmit={handleSubmit}>
						<div className="col-12">
							<div className="mb-4 mt-3 col-12 d-flex justify-content-center">
								<OtpInput value={otp} onChange={handleOTP} numInputs={6} inputStyle="otp_style" />
							</div>
						</div>

						<button className="primary_btn mt-4">
							{mutation.isLoading && (
								<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-3" />
							)}
							Reset Password
						</button>
					</form>
				</div>
			</div>
		</OnboardingLayout>
	);
};

export default PasswordReset;
