import { axiosPublic } from './axios/axios-public';
import mem from 'mem';

export const toFormData = (payload) => {
	const formData = new FormData();
	Object.keys(payload).forEach((key) => {
		formData.append(key, payload[key]);
	});
	return formData;
};

const refreshToken = async () => {
	const token = JSON.parse(localStorage.getItem('swiftwaze_tokens'));
	const td = toFormData({
		refresh_token: token?.refresh_token,
	});
	try {
		const res = await axiosPublic.post('/refresh', td);
		let data = res?.data;
		if (data?.status) {
			localStorage.setItem('swiftwaze_tokens', JSON.stringify(data?.data));
		}

		// console.log(data, 'refresh');
		return res;
	} catch (error) {
		// console.log(error);
		localStorage.removeItem('swiftwaze_tokens');
	}
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshToken, {
	maxAge,
});
