import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { axiosPrivate } from '../../../../shared/helpers/axios/axios-private';
import * as yup from 'yup';
import { toFormData } from '../../../../shared/helpers/authHelpers';
import ButtonComponent from '../../../../shared/components/buttonComponent';

const schema = yup.object({
	old_pin: yup.number().required('Old pin is required'),
	new_pin: yup.number().required('New pin is required'),
	confirm_pin: yup.number().required('Confirm new pin'),
});

const TransactionPin = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const setPin = async (data) => {
		setLoading(true);
		const payload = {
			old_pin: data.old_pin,
			new_pin: data.new_pin,
		};
		try {
			const res = await axiosPrivate.post(`/updatetrxpin`, toFormData(payload));

			enqueueSnackbar(res.data.msg, {
				variant: res.data.status ? 'success' : 'error',
			});

			setLoading(false);
			reset();
		} catch (error) {
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
			setLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(setPin)}>
			<h4>Update Transaction Pin</h4>
			<div className="row mt-4">
				<div className="col-md-4 d-flex align-items-center">
					<label htmlFor="old_pin">Old Transaction Pin</label>
				</div>
				<div className="col-md-8">
					<input type="password" maxLength={4} className="w-100 input_style border rounded" {...register('old_pin')} />
					<p className="text-danger f-10 mt-2">{errors.old_pin?.message}</p>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-md-4 d-flex align-items-center">
					<label htmlFor="new_pin">New Transaction Pin</label>
				</div>
				<div className="col-md-8">
					<input type="password" maxLength={4} className="w-100 input_style border rounded" {...register('new_pin')} />
					<p className="text-danger f-10 mt-2">{errors.new_pin?.message}</p>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-md-4 d-flex align-items-center">
					<label htmlFor="confirm_pin">Confirm Pin</label>
				</div>
				<div className="col-md-8">
					<input
						type="password"
						maxLength={4}
						className="w-100 input_style border rounded"
						{...register('confirm_pin')}
					/>
					<p className="text-danger f-10 mt-2">{errors.confirm_pin?.message}</p>
				</div>
			</div>

			<div className="row mt-4">
				<div className="col-md-4 d-flex align-items-center"></div>
				<div className="col-md-8">
					<ButtonComponent
						props={{
							anchor: 'Update Pin',
							style: 'onboarding_btn w-100 text-uppercase f-14',
							isLoading: loading,
						}}
					/>
				</div>
			</div>
		</form>
	);
};

export default TransactionPin;
