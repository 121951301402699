import UserComponent from '../userProfile/userComponent';

const DashboardRightPanel = ({ children }) => {
	return (
		<div className="p-lg-4 w-100 vh-100 bg-white border-start remove_border">
			<UserComponent />
			<div className="col-12">{children}</div>
		</div>
	);
};

export default DashboardRightPanel;
