import { BrowserRouter, Navigate, Route, Routes as Router } from 'react-router-dom';
import { Suspense, lazy, useState, useEffect } from 'react';
import About from '../../pages/about/about';
// import Dashboard from '../../pages/app/dashboard/dashboard';
import Document from '../../pages/app/profile/document/document';
import GeneralProfile from '../../pages/app/profile/general/general';
import Help from '../../pages/app/profile/help/help';
import Privacy from '../../pages/app/profile/privacy/privacy';
import Security from '../../pages/app/profile/security/security';
import Wallet from '../../pages/app/wallet/wallet';
import Blog from '../../pages/blog/blog';
import BlogDetail from '../../pages/blog/blog-detail/detail';
import Homepage from '../../pages/homepage/homepage';
import ForgotPassword from '../../pages/onboarding/passwordReset/forgot-password';
import PasswordReset from '../../pages/onboarding/passwordReset/resetPassword';
import ChangePassword from '../../pages/onboarding/passwordReset/changePassword';
import SignIn from '../../pages/onboarding/sign-in';
import SignUp from '../../pages/onboarding/sign-up';
import Support from '../../pages/support/support';
import WhySwiftwaze from '../../pages/why-swiftwaze/why-swiftwaze';
import PrivateRoutes from './privateRoutes';
import VerifyEmail from '../../pages/onboarding/verify-email';
import Error404 from '../../pages/Error404/Error404';
import Dashboard from '../../pages/app/dashboard/dashboard';
import SuspendedScreen from '../components/SuspendedScreen';
import Topup from '../../pages/app/transaction/topup/Topup';
import SendMoney from '../../pages/app/transaction/send-money/SendMoney';

const Routes = () => {
	// const Dashboard = lazy(() => import('../../pages/app/dashboard/dashboard'));
	// const GeneralProfile = lazy(() => import('../../pages/app/profile/general/general'));

	return (
		<BrowserRouter>
			<Router>
				<Route path="/" element={<Homepage />} />
				<Route path="/about" element={<About />} />
				<Route path="/why-swiftwaze" element={<WhySwiftwaze />} />
				<Route path="/contact" element={<Support />} />
				<Route path="/blog" element={<Blog />} />
				<Route path="/blog/:id" element={<BlogDetail />} />
				<Route path="/sign-in" element={<SignIn />} />
				<Route path="/sign-up" element={<SignUp />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/password-reset" element={<PasswordReset />} />
				<Route path="/change-password" element={<ChangePassword />} />
				<Route path="/verify-email" element={<VerifyEmail />} />

				<Route element={<PrivateRoutes />}>
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/wallets" element={<Wallet />} />

					{/* Transactions Routes */}
					<Route path="/transactions" element={<Topup />} />
					<Route path="/transactions/topup" element={<Topup />} />
					<Route path="/transactions/transfers" element={<SendMoney />} />

					{/* Profile Routes */}
					<Route path="/profile/">
						<Route index element={<GeneralProfile />} />
						<Route path="security" element={<Security />} />
						<Route path="help" element={<Help />} />
						<Route path="privacy" element={<Privacy />} />
						<Route path="documents" element={<Document />} />
					</Route>
				</Route>
				<Route path="*" element={<Error404 />} />
			</Router>
		</BrowserRouter>
	);
};

const SuspendedView = ({ children }) => {
	const [showSuspense, setShowSuspense] = useState(true);

	useEffect(() => {
		setShowSuspense(false);
	}, []);
	return <>{showSuspense ? <Suspense fallback={<SuspendedScreen />}>{children}</Suspense> : children}</>;
};

export default Routes;
