import { useEffect, useState } from 'react';
import { avatar } from '../../../../assets/images/imageComponents';
import ButtonComponent from '../../../../shared/components/buttonComponent';
import Profile from '../profile';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from 'react-query';
import { Spinner } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import { toFormData } from '../../../../shared/helpers/authHelpers';
import { axiosPrivate } from '../../../../shared/helpers/axios/axios-private';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from '../../../../shared/redux/authSlice';
import { setUser } from '../../../../shared/redux/authSlice';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { axiosPublic } from '../../../../shared/helpers/axios/axios-public';
import { Select } from 'antd';

const schema = yup.object({
	fname: yup.string().required('First Name is required').min(3).max(50),
	lname: yup.string().required('Last Name is required').min(3).max(50),
	phone: yup
		.number()
		.typeError("That doesn't look like a phone number")
		.positive("A phone number can't start with a minus")
		.integer("A phone number can't include a decimal point")
		.min(11)
		.required('A phone number is required'),
	address_line: yup.string().required('Street Address is required'),
	address_city: yup.string().required('City is required'),
	address_state: yup.string().required('State is required'),
	address_country_id: yup.string().required('Country is required'),
	address_postalcode: yup.string().required('Postal Code is required'),
});

const GeneralProfile = () => {
	const { user } = useSelector((state) => state.auth);
	const [image, setImage] = useState(null);
	const [imgUrl, setImgUrl] = useState(null);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const mutation = useMutation((formData) => {
		return axiosPrivate.post(`/profile`, formData);
	});

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	// Get list of countries
	const { data: countryList } = useQuery('country-list', () => {
		return axiosPublic.get(`/common/countries`);
	});
	console.log(countryList, 'countryList');

	const updateProfile = async (data) => {
		const payload = { ...data, img: image };
		// console.log(payload, 'data');
		try {
			const res = await mutation.mutateAsync(toFormData(payload));
			// console.log(res, 'profile update');
			enqueueSnackbar(res.data.msg, {
				variant: res.data.status ? 'success' : 'error',
			});
			if (res?.data?.status) {
				dispatch(fetchUser());
				// dispatch(setUser(res?.data?.data));
			}
		} catch (error) {
			// console.log(error, 'error');
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
		}
	};

	const handleImage = (e) => {
		const file = e.target.files[0];
		// console.log(URL.createObjectURL(file));
		setImage(file); // sent to server
		setImgUrl(URL.createObjectURL(file)); // to be displayed
	};

	useEffect(() => {
		// console.log(user, 'user');
		reset({
			fname: user?.fname,
			lname: user?.lname,
			phone: user?.phone,
			address_line: user?.address_line,
			address_city: user?.address_city,
			address_state: user?.address_state,
			address_country_id: user?.address_country_id,
			address_postalcode: user?.address_postalcode,
		});
	}, [user]);
	return (
		<>
			<Profile>
				<form onSubmit={handleSubmit(updateProfile)} className="col-lg-10 col-xl-8 p-lg-5 pt-lg-4 general_section">
					<div className="row">
						<div className="col-md-4">
							<h6>Your Photo</h6>
						</div>
						<div className="col-md-8 text-center">
							{imgUrl ? (
								<img height={120} width={120} className="rounded-circle" src={imgUrl} alt="" />
							) : (
								<img height={120} width={120} className="rounded-circle" src={user?.img_url || avatar} alt="" />
							)}
							<br />
							<input className="d-none" accept="image/*" type="file" name="file" id="file" onChange={handleImage} />
							<label htmlFor="file" className="mt-3 upload_btn border-0 bg-transparent cursor">
								Upload Image
							</label>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center">
							<label htmlFor="fname">First Name</label>
						</div>
						<div className="col-md-8">
							<input type="text" className="w-100 input_style border rounded" {...register('fname')} />
							<p className="text-danger f-10 mt-2">{errors.fname?.message}</p>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center">
							<label htmlFor="lname">Last Name</label>
						</div>
						<div className="col-md-8">
							<input type="text" className="w-100 input_style border rounded" {...register('lname')} />
							<p className="text-danger f-10 mt-2">{errors.lname?.message}</p>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center">
							<label htmlFor="phone">Phone Number</label>
						</div>
						<div className="col-md-8">
							<Controller
								name="phone"
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<PhoneInput
										{...field}
										inputExtraProps={{
											required: true,
											min: 11,
										}}
										country={'ng'}
										placeholder=""
										countryCodeEditable={false}
										enableSearch={true}
										disableSearchIcon={true}
										inputClass={`w-100 input_style border rounded`}
									/>
								)}
							/>
							<p className="text-danger f-10 mt-2">{errors.phone?.message}</p>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center">
							<h6>Street Address</h6>
						</div>
						<div className="col-md-8">
							<input type="text" className="w-100 input_style border rounded" {...register('address_line')} />
							<p className="text-danger f-10 mt-2">{errors.address_line?.message}</p>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center">
							<h6>City</h6>
						</div>
						<div className="col-md-8">
							<input type="text" className="w-100 input_style border rounded" {...register('address_city')} />
							<p className="text-danger f-10 mt-2">{errors.address_city?.message}</p>
						</div>
					</div>

					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center">
							<h6>State</h6>
						</div>
						<div className="col-md-8">
							<input type="text" className="w-100 input_style border rounded" {...register('address_state')} />
							<p className="text-danger f-10 mt-2">{errors.address_state?.message}</p>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center">
							<h6>Postal Code</h6>
						</div>
						<div className="col-md-8">
							<input type="text" className="w-100 input_style border rounded" {...register('address_postalcode')} />
							<p className="text-danger f-10 mt-2">{errors.address_postalcode?.message}</p>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center">
							<h6>Country</h6>
						</div>
						<div className="col-md-8">
							<Controller
								name="address_country_id"
								control={control}
								rules={{ required: true }}
								render={({ field }) => (
									<Select
										{...field}
										showSearch
										bordered={false}
										optionFilterProp="children"
										filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
										className="w-100 country_field shadow-sm"
										options={countryList?.data?.data?.map((country) => {
											return { label: country?.name, value: country?.id };
										})}
									/>
								)}
							/>
							<p className="text-danger f-10 mt-2">{errors.address_country_id?.message}</p>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-md-4 d-flex align-items-center"></div>
						<div className="col-md-8">
							<ButtonComponent
								props={{
									anchor: 'Update Profile',
									style: 'onboarding_btn w-100 text-uppercase f-14',
									isLoading: mutation.isLoading,
								}}
							/>
						</div>
					</div>
				</form>
			</Profile>
		</>
	);
};

export default GeneralProfile;
