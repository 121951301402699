import { useState } from "react";
import { InputText } from "primereact/inputtext";

const SearchComponent = ({ props }) => {
  const [value, setValue] = useState("");
  return (
    <span className="p-float-label p-input-icon-left">
      <i className="pi pi-search" />
      <InputText
        className={`${props.style && props.style}`}
        id="lefticon"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <label
        htmlFor="lefticon"
        className={`${props.floatStyle && props.floatStyle}`}
      >
        {props.anchor}
      </label>
    </span>
  );
};

export default SearchComponent;
