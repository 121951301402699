import React from 'react';
import { useState } from 'react';
import { Select, Radio } from 'antd';
import CardSection from './CardSection';
import TransferSection from './TransferSection';
import SavedCard from './SavedCard';
import SwiftWallet from './SwiftWallet';

const FundingSource = () => {
	const [card, setCard] = useState('new card');
	const { Option } = Select;

	const onChange = (e) => {
		console.log('radio checked', e.target.value);
		setCard(e.target.value);
	};
	return (
		<div className="mt-2">
			<label className="mb-2 text_secondary">Payment Method</label>
			<div className="col-12">
				{/* <Select className="wallet_select" defaultValue="ngn" onChange={onChange}>
					<Option value="swift-wallet">Swift Wallet</Option>
					<Option value="saved-card">Saved Cards</Option>
					<Option value="swift-wallet">New Card</Option>
				</Select> */}
				<Radio.Group style={{ color: 'red' }} className="d-flex" onChange={onChange} value={card}>
					<Radio value="swift-wallet">Swift Wallet</Radio>
					<Radio value="new-card">New Card</Radio>
					<Radio value="saved-card">Saved Card</Radio>
				</Radio.Group>
			</div>
			{card === 'swift-wallet' && (
				<div className="mt-4">
					<SwiftWallet />
				</div>
			)}
			{card === 'new-card' && (
				<div>
					<CardSection />
				</div>
			)}
			{card === 'saved-card' && (
				<>
					{[1, 2].map((card, i) => (
						<div className="col-12 mt-4" key={i}>
							<SavedCard />
						</div>
					))}
				</>
			)}
			{/* {card === 'transfer' && (
				<div>
					<TransferSection />
				</div>
			)} */}
		</div>
	);
};

export default FundingSource;
