import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { axiosConfig, url } from '../../../shared/config';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import OnboardingLayout from '../../../shared/layouts/onboardingLayout';
import * as yup from 'yup';
import { toFormData } from '../../../shared/helpers/authHelpers';
import { axiosPublic } from '../../../shared/helpers/axios/axios-public';

const ForgotPassword = () => {
	const { enqueueSnackbar } = useSnackbar();
	const mutation = useMutation((formData) => {
		return axiosPublic.post(`/passwordreset`, formData);
	});

	const navigate = useNavigate();
	const schema = yup.object({
		email: yup.string().required('Email is required').email('Invalid email format').max(300),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (payload) => {
		try {
			const res = await mutation.mutateAsync(toFormData(payload));
			let data = res.data;
			enqueueSnackbar(data?.msg, {
				variant: data?.status ? 'success' : 'error',
			});
			data?.status && navigate('/password-reset', { state: { email: payload.email } });
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	};

	return (
		<OnboardingLayout>
			<div className="onboarding_scroll_y d-flex align-items-center">
				<div className="col-12">
					<h3 className="fw-bold text-center mb-4">Forgot Password</h3>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="col-12">
							<label htmlFor="email">Enter your email address</label>
							<input type="email" className="w-100 onboarding_field shadow-sm" {...register('email')} />
							<p className="mt-2 text-danger f-12">{errors.email?.message}</p>
						</div>
						<button className="primary_btn mt-4">
							{mutation.isLoading && (
								<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-3" />
							)}
							Reset Password
						</button>
					</form>
				</div>
			</div>
		</OnboardingLayout>
	);
};

export default ForgotPassword;
