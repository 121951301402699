import { NavLink } from 'react-router-dom';
import { avatar } from '../../../assets/images/imageComponents';
import IconsComponent from '../../../assets/icons/iconsComponent';
import { useSelector } from 'react-redux';

const UserComponent = () => {
	const { user } = useSelector((state) => state.auth);
	return (
		<div className="d-flex align-items-center">
			<div>
				<IconsComponent props="notification-icon" />
			</div>
			<NavLink className="text-decoration-none" to="/profile">
				<div className="d-flex justify-content-center align-items-center">
					<img className="mx-2 rounded-circle" height={40} width={40} src={user?.img_url || avatar} alt="" />
					<div className="">
						<h6 className="mb-1">
							{user?.fname} {user?.lname}
						</h6>
						<span className="text-muted">{user?.email}</span>
					</div>
				</div>
			</NavLink>
		</div>
	);
};

export default UserComponent;
