import React from 'react';
import Routes from './shared/routes/routes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import SuspendedScreen from './shared/components/SuspendedScreen';

const App = () => {
	const Routes = React.lazy(() => import('./shared/routes/routes'));
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnWindowFocus: false,
				refetchOnMount: false,
				cacheTime: 1 * 60 * 60 * 1000,
				staleTime: 1 * 60 * 60 * 1000,
				retry: 2,
			},
		},
	});

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<SnackbarProvider
					maxSnack={3}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					autoHideDuration={3000}
				>
					<Suspense fallback={<SuspendedScreen />}>
						<Routes />
					</Suspense>
				</SnackbarProvider>
			</QueryClientProvider>
		</>
	);
};

export default App;
