import { useLocation, useNavigate } from 'react-router-dom';
import { bank_icon } from '../../../assets/images/imageComponents';
import ButtonComponent from '../../../shared/components/buttonComponent';
import FloatInputField from '../../../shared/components/floatInputField';
import UserComponent from '../../../shared/components/userProfile/userComponent';
import DashboardLayout from '../../../shared/layouts/dashboardLayout';
import TransactionDetails from './TransactionDetails';
import { useSelector, useDispatch } from 'react-redux';
import { setVisibility } from '../../../shared/redux/transactionSlice';
import { Drawer } from 'antd';
import { useState } from 'react';

const Transactions = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { isVisible } = useSelector((state) => state.transaction);

	return (
		<>
			<DashboardLayout>
				<div className="scroll_none p-3">
					<div className="row">
						<div className="scroll_y vh-100">
							<div className="container">
								<div className="py-4">
									<div className="d-flex block justify-content-between">
										<h3 className="dashboard_header">Transactions</h3>
										<FloatInputField
											props={{
												label: 'Search',
												type: 'search',
												style: 'w-100 input_style rounded shadow',
											}}
										/>
										<UserComponent/>
									</div>

									<div className="container">
										<div className="mt-5 d-flex justify-content-between mb-4">
											<div className="d-flex align-items-center block">
												<button
													onClick={() => navigate('/transactions/topup')}
													className={`bg-transparent border-0 me-4 pb-2 ${
														(location.pathname === '/transactions' ||
														location.pathname === '/transactions/topup') && 'active_tab'
													}`}
												>
													Top-Ups
												</button>
												<button
													onClick={() => navigate('/transactions/transfers')}
													className={`bg-transparent border-0 pb-2 ${
														location.pathname === '/transactions/transfers' && 'active_tab'
													}`}
												>
													Transfers
												</button>
											</div>
											<div className="btn-group">
												<button
													type="button"
													className="bg-white border px-4 py-2 dropdown-toggle"
													data-bs-toggle="dropdown"
													aria-expanded="false"
												>
													Duration
												</button>
												<ul className="dropdown-menu dropdown-menu-end">
													<li>
														<button className="dropdown-item" type="button">
															Daily
														</button>
													</li>
													<li>
														<button className="dropdown-item" type="button">
															Monthly
														</button>
													</li>
												</ul>
											</div>
										</div>
										{children}
									</div>
								</div>
							</div>
						</div>
					</div>
					<Drawer
						closable={true}
						placement="right"
						width={430}
						onClose={() => dispatch(setVisibility(false))}
						visible={isVisible}
					>
						<TransactionDetails />
					</Drawer>
				</div>
			</DashboardLayout>
		</>
	);
};

export default Transactions;
