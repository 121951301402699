import { pie_chart, star } from "../../assets/images/imageComponents";
import ButtonComponent from "./buttonComponent";

const Subscription = () => {
  return (
    <div className="subscribe_bg position-relative">
      <img src={star} alt="" className="star" />
      <img src={pie_chart} alt="" className="pie" />
      <div className="wrapper">
        <div className="row">
          <div className="">
            <h4 className="subscribe_header col-10 col-lg-4 fw-bold text-center mx-auto">
              Lorem Ipsum is simply dummy text of the printing.
            </h4>
          </div>
          <div className="col-lg-5 mx-auto">
            <div className="mt-5 d-flex align-items-center block justify-content-center">
              <div className="sm_center">
                <input
                  type="email"
                  placeholder="Enter your email"
                  name=""
                  id=""
                  className="subscription_email"
                />
              </div>
              <div className="sm_center">
                <ButtonComponent
                  props={{
                    anchor: "Subscribe",
                    style: "subscribe_btn px-5 ms-md-3 border-0",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
