import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { axiosPrivate } from '../../../../shared/helpers/axios/axios-private';
import * as yup from 'yup';
import { toFormData } from '../../../../shared/helpers/authHelpers';
import ButtonComponent from '../../../../shared/components/buttonComponent';
import { fetchUser } from '../../../../shared/redux/authSlice';
import { useDispatch } from 'react-redux';

const schema = yup.object({
	pin: yup.number().required('Pin is required'),
	confirm_pin: yup.number().required('Confirm pin'),
});

const SetPinModal = ({ setShow, show }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const handleClose = () => {
		setShow(false);
	};

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const setPin = async (data) => {
		setLoading(true);
		console.log(data);
		const payload = {
			pin: data.pin,
		};
		try {
			const res = await axiosPrivate.post(`/settrxpin`, toFormData(payload));

			enqueueSnackbar(res.data.msg, {
				variant: res?.data?.status ? 'success' : 'error',
			});
			if (res?.data?.status) {
				setShow(false);
				dispatch(fetchUser());
			}
			setLoading(false);
			reset();
		} catch (error) {
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
			setLoading(false);
		}
	};

	return (
		<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
			<div className="container">
				<form onSubmit={handleSubmit(setPin)}>
					<Modal.Header className="border-0">
						<Modal.Title className="text-danger text-center text-uppercase">Set Transaction Pin</Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center f-14">
						{/* <h4>Set Transaction Pin</h4> */}
						<div className="row mt-2">
							<div className="col-md-12 d-flex align-items-center">
								<label htmlFor="pin">Transaction Pin</label>
							</div>
							<div className="col-md-12">
								<input
									type="password"
									maxLength={4}
									className="w-100 input_style border rounded"
									{...register('pin')}
								/>
								<p className="text-danger align-start f-10 mt-2">{errors.pin?.message}</p>
							</div>
						</div>
						<div className="row mt-2">
							<div className="col-md-12 d-flex align-items-center">
								<label htmlFor="confirm_pin">Confirm Pin</label>
							</div>
							<div className="col-md-12">
								<input
									type="password"
									maxLength={4}
									className="w-100 input_style border rounded"
									{...register('confirm_pin')}
								/>
								<p className="text-danger text-start f-10 mt-2">{errors.confirm_pin?.message}</p>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer className="border-0 my-2">
						<ButtonComponent
							props={{
								anchor: 'Set Pin',
								style: 'onboarding_btn w-100 text-uppercase f-14',
								isLoading: loading,
							}}
						/>
					</Modal.Footer>
				</form>
			</div>
		</Modal>
	);
};

export default SetPinModal;
