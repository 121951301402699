import ButtonComponent from "../../../../shared/components/buttonComponent";
import Profile from "../profile";

const Privacy = () => {
  return (
    <>
      <Profile>
        <div className="col-12 p-lg-4 general_section">
          <div className="row">
            <div className="col-12 mb-4">
              <h4>Privacy Policy</h4>
              <p className="mt-4 profile_body_color">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
                veniam obcaecati adipisci est ad nostrum fugit in, perspiciatis
                delectus dolorem nisi nemo quos dolorum amet debitis
                praesentium! Ratione eaque sapiente, mollitia voluptate
                explicabo nesciunt itaque similique quisquam! Nisi hic quibusdam
                dignissimos expedita alias reprehenderit veniam enim laudantium
                earum blanditiis eaque cupiditate velit numquam, assumenda
                voluptatibus, aliquam placeat non vero delectus, laboriosam et
                nam officiis. Vero aperiam mollitia ut porro sapiente?
              </p>
              <p className="mt-4 profile_body_color">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
                veniam obcaecati adipisci est ad nostrum fugit in, perspiciatis
                delectus dolorem nisi nemo quos dolorum amet debitis
                praesentium! Ratione eaque sapiente, mollitia voluptate
                explicabo nesciunt itaque similique quisquam! Nisi hic quibusdam
                dignissimos expedita alias reprehenderit veniam enim laudantium
                earum blanditiis eaque cupiditate velit numquam, assumenda
                voluptatibus, aliquam placeat non vero delectus, laboriosam et
                nam officiis. Vero aperiam mollitia ut porro sapiente?
              </p>
            </div>
            <div className="col-12 mb-4">
              <h4>Terms and Conditions</h4>
              <p className="mt-4 profile_body_color">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
                veniam obcaecati adipisci est ad nostrum fugit in, perspiciatis
                delectus dolorem nisi nemo quos dolorum amet debitis
                praesentium! Ratione eaque sapiente, mollitia voluptate
                explicabo nesciunt itaque similique quisquam! Nisi hic quibusdam
                dignissimos expedita alias reprehenderit veniam enim laudantium
                earum blanditiis eaque cupiditate velit numquam, assumenda
                voluptatibus, aliquam placeat non vero delectus, laboriosam et
                nam officiis. Vero aperiam mollitia ut porro sapiente?
              </p>
              <p className="mt-4 profile_body_color">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae
                veniam obcaecati adipisci est ad nostrum fugit in, perspiciatis
                delectus dolorem nisi nemo quos dolorum amet debitis
                praesentium! Ratione eaque sapiente, mollitia voluptate
                explicabo nesciunt itaque similique quisquam! Nisi hic quibusdam
                dignissimos expedita alias reprehenderit veniam enim laudantium
                earum blanditiis eaque cupiditate velit numquam, assumenda
                voluptatibus, aliquam placeat non vero delectus, laboriosam et
                nam officiis. Vero aperiam mollitia ut porro sapiente?
              </p>
            </div>
            <div className="col-md-4 mt-4">
              <ButtonComponent
                props={{
                  anchor: "I Accept",
                  style: "onboarding_btn w-100 text-uppercase f-14",
                }}
              />
            </div>
          </div>
        </div>
      </Profile>
    </>
  );
};

export default Privacy;
