import Transactions from '../transaction';
import TableComponent from '../../../../shared/components/tableComponent';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchSendMoneyList, fetchTopUpList } from '../../../../shared/redux/transactionSlice';
import { toFormData } from '../../../../shared/helpers/authHelpers';

const SendMoney = () => {
	const dispatch = useDispatch();
	const { topups, loadList } = useSelector((state) => state.transaction);
	const [data, setData] = useState({
		page: 1,
		per_page: 8,
		transaction_type: 'sendmoney',
	});

	useEffect(() => {
		dispatch(fetchTopUpList(toFormData(data)));
	}, [data]);

	return (
		<Transactions>
			<div className="table-responsive">
				<TableComponent type="sendmoney" transactions={topups} loading={loadList} setData={setData} />
			</div>
		</Transactions>
	);
};

export default SendMoney;
