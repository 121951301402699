import ChangePassword from './ChangePassword';
import TransactionPin from './TransactionPin';
import Profile from '../profile';

const Security = () => {
	return (
		<>
			<Profile>
				<div>
					<TransactionPin />
				</div>
				<div className="mt-5 pt-3">
					<ChangePassword />
				</div>
			</Profile>
		</>
	);
};

export default Security;
