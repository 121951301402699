import { useState } from "react";
import { Select } from "antd";
import OtpInput from "react-otp-input";
import ButtonComponent from "../../../../shared/components/buttonComponent";
import FloatInputField from "../../../../shared/components/floatInputField";
import TransferSuccess from "../transferMoney/transferSuccess";

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { Option } = Select;

const PayBills = () => {
  const [otp, setOtp] = useState("");
  const [payBillScreen, setPayBillScreen] = useState(true);
  const [otpScreen, setOtpScreen] = useState(false);
  const [successScreen, setSuccessScreen] = useState(false);

  const handleOTP = (otp) => setOtp(otp);

  const handleOtpScreen = (e) => {
    e.preventDefault();
    setPayBillScreen(!payBillScreen);
    setOtpScreen(!otpScreen);
  };

  const handleSuccessScreen = (e) => {
    e.preventDefault();
    setOtpScreen(!otpScreen);
    setSuccessScreen(!successScreen);
  };

  return (
    <div className="container">
      {payBillScreen && (
        <>
          <h3 className="drawer_header">Pay Bills</h3>
          <p className="drawer_subheader">
            To make a transfer provide the detials below
          </p>
          <div className="mt-5">
            <Select defaultValue="lucy" onChange={handleChange}>
              <Option value="jack">NGN Wallet Balance</Option>
              <Option value="lucy">Dollar Wallet Balance</Option>
            </Select>
            <h3 className="mt-2 balance_header">₦ 3,041,730</h3>
            <form action="" className="row">
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Select Bill",
                    style: "w-100",
                    type: "dropdown",
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Choose a Provider",
                    style: "w-100",
                    type: "dropdown",
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Select Package",
                    style: "w-100",
                    type: "dropdown",
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Amount",
                    type: "number",
                    style: "w-100 input_style rounded border",
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <FloatInputField
                  props={{
                    label: "Enter IUC Number",
                    type: "number",
                    style: "w-100 input_style rounded border",
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <ButtonComponent
                  props={{
                    anchor: "proceed",
                    style: "onboarding_btn w-100 text-uppercase",
                    handleClick: handleOtpScreen,
                  }}
                />
              </div>
            </form>
          </div>
        </>
      )}
      {otpScreen && (
        <>
          <h3 className="drawer_header">Transaction Pin</h3>
          <p className="drawer_subheader">
            Provide transaction to fund wallet from card
          </p>
          <div className="mt-5">
            <form action="" className="row">
              <div className="col-12 mt-5">
                <p className="otp_text">Enter your 4 digit pin</p>
                <div className="mb-5 col-12 d-flex justify-content-center">
                  <OtpInput
                    value={otp}
                    onChange={handleOTP}
                    numInputs={4}
                    inputStyle="otp_style"
                  />
                </div>
                <div className="col-12">
                  <ButtonComponent
                    props={{
                      anchor: "Transfer",
                      style: "onboarding_btn w-100 text-uppercase",
                      handleClick: handleSuccessScreen,
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
      {successScreen && (
        <>
          <TransferSuccess />
        </>
      )}
    </div>
  );
};

export default PayBills;
