import { useState } from "react";
import ApexCharts from "react-apexcharts";

const ChartComponent = () => {
  const [series] = useState([
    {
      name: "Desktops",
      data: [10, 41, 88, 32, 55, 77, 23, 56, 11, 91, 35, 51, 49, 62, 69, 91],
    },
  ]);
  const [options] = useState({
    chart: {
      height: 450,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      categories: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
      ],
    },
  });

  return (
    <div className="mt-4">
      <div className="border">
        <div className="row">
          <div className="px-4 py-2 w-100">
            <ApexCharts
              options={options}
              series={series}
              type="area"
              height={350}
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
