import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { axiosPublic } from '../../../shared/helpers/axios/axios-public';
import * as yup from 'yup';
import OnboardingLayout from '../../../shared/layouts/onboardingLayout';
import { toFormData } from '../../../shared/helpers/authHelpers';

const schema = yup.object({
	new_pass: yup
		.string()
		.required('Password is required')
		.min(8, 'Minimum of 8 characters')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			'Use 8 or more characters with a mix of uppercase/lowercase letters, numbers & symbols'
		),
});

const ChangePassword = () => {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const location = useLocation();

	const mutation = useMutation((formData) => {
		return axiosPublic.post(`/setnewpass`, formData);
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const changePassword = async (payload) => {
		console.log(payload, 'payload');
		try {
			const res = await mutation.mutateAsync(toFormData({ ...payload, email: location?.state?.email }));
			let data = res?.data;

			enqueueSnackbar(data?.msg, {
				variant: data?.status ? 'success' : 'error',
			});

			data?.status && navigate('/sign-in');
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	};

	return (
		<OnboardingLayout>
			<div className="onboarding_scroll_y d-flex align-items-center">
				<div className="col-12">
					<div className="text-center mb-4">
						<h3 className="fw-bold">New Password</h3>
						{/* <p className="home_text text-muted">Enter a new password</p> */}
					</div>
					<form onSubmit={handleSubmit(changePassword)}>
						<div className="col-12">
							<label htmlFor="pword">Password</label>
							<input type="password" className="w-100 onboarding_field shadow-sm" {...register('new_pass')} />
							<p className="mt-2 text-danger f-12">{errors.new_pass?.message}</p>
						</div>
						<button className="primary_btn mt-4">
							{mutation.isLoading && (
								<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-3" />
							)}
							Change Password
						</button>
					</form>
				</div>
			</div>
		</OnboardingLayout>
	);
};

export default ChangePassword;
