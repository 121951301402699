import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { axiosPrivate } from '../../../../shared/helpers/axios/axios-private';
import * as yup from 'yup';
import { toFormData } from '../../../../shared/helpers/authHelpers';
import ButtonComponent from '../../../../shared/components/buttonComponent';

const schema = yup.object({
	old_pword: yup.string().required('Enter your old password'),
	new_pword: yup
		.string()
		.required('Enter your new password')
		.min(8, 'Minimum of 8 characters')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			'Use 8 or more characters with a mix of uppercase/lowercase letters, numbers & symbols'
		),
	confirm_pword: yup
		.string()
		.required('Password confirmation is required').oneOf([yup.ref('new_pword'), null], 'Passwords must match'),
});

const ChangePassword = () => {
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const changePassword = async (data) => {
		setLoading(true);
        const payload ={
            old_pword: data.old_pword,
            new_pword: data.new_pword,
        }
		try {
			const res = await axiosPrivate.post(`/changepass`, toFormData(payload));

			enqueueSnackbar(res.data.msg, {
				variant: res.data.status ? 'success' : 'error',
			});
            setLoading(false);
			reset();
		} catch (error) {
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
			setLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(changePassword)}>
			<h4>Change Password</h4>
			<div className="row mt-4">
				<div className="col-md-4 d-flex align-items-center">
					<label htmlFor="old_pword">Old Password</label>
				</div>
				<div className="col-md-8">
					<input type="password" className="w-100 input_style border rounded" {...register('old_pword')} />
					<p className="text-danger f-10 mt-2">{errors.old_pword?.message}</p>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-md-4 d-flex align-items-center">
					<label htmlFor="new_pword">New Password</label>
				</div>
				<div className="col-md-8">
					<input type="password" className="w-100 input_style border rounded" {...register('new_pword')} />
					<p className="text-danger f-10 mt-2">{errors.new_pword?.message}</p>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-md-4 d-flex align-items-center">
					<label htmlFor="confirm_pword">Confirm Password</label>
				</div>
				<div className="col-md-8">
					<input type="password" className="w-100 input_style border rounded" {...register('confirm_pword')} />
					<p className="text-danger f-10 mt-2">{errors.confirm_pword?.message}</p>
				</div>
			</div>

			<div className="row mt-4">
				<div className="col-md-4 d-flex align-items-center"></div>
				<div className="col-md-8">
					<ButtonComponent
						props={{
							anchor: 'Change Password',
							style: 'onboarding_btn w-100 text-uppercase f-14',
							isLoading: loading,
						}}
					/>
				</div>
			</div>
		</form>
	);
};

export default ChangePassword;
