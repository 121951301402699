import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { axiosPublic } from '../../shared/helpers/axios/axios-public';
import OnboardingLayout from '../../shared/layouts/onboardingLayout';
import * as yup from 'yup';
import { toFormData } from '../../shared/helpers/authHelpers';

const schema = yup.object({
	token: yup.string().required('Token is required').max(6),
});

const VerifyEmail = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { email } = location.state;
	const { enqueueSnackbar } = useSnackbar();

	const verification = useMutation((formData) => axios.post(`/verifyacct`, formData));
	const resend = useMutation((formData) => axiosPublic.post(`/resendacctverification`, formData));

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleVerification = async (payload) => {
		try {
			const data = await verification.mutateAsync(toFormData({ ...payload, email }));
			// console.log(data, 'verify data');

			enqueueSnackbar(data.data.msg, {
				variant: data.data.status ? 'success' : 'error',
			});
			data.data.status && navigate('/sign-in');
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	};

	const resendCode = async () => {
		try {
			const data = await resend.mutateAsync(toFormData({ email }));

			enqueueSnackbar(data.data.msg, {
				variant: data.data.status ? 'success' : 'error',
			});
			data.data.status && navigate('/sign-in');
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	};
	return (
		<OnboardingLayout>
			<div className="onboarding_scroll_y">
				<div className="text-center">
					<h3 className="fw-bold">Email Verification</h3>
					<p className="home_text text-muted">
						Enter the code sent to <span className="">{email}</span>
					</p>
				</div>
				<form onSubmit={handleSubmit(handleVerification)}>
					<div className="col-12">
						{/* <label htmlFor="email">Email</label> */}
						<input type="text" maxLength={6} className="w-100 onboarding_field shadow-sm" {...register('token')} />
						<p className="mt-2 text-danger f-12">{errors.token?.message}</p>
					</div>

					<button className="primary_btn mt-4">
						{verification.isLoading && (
							<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-3" />
						)}
						Verify
					</button>
				</form>
				<div className={`d-flex justify-content-center mt-4`}>
					<p>Didn't get code?</p>
					<p
						className={`ms-1 ${resend.isLoading ? 'cursor-none text-muted' : 'cursor text-primary'}`}
						onClick={resendCode}
					>
						Resend code
					</p>
				</div>
			</div>
		</OnboardingLayout>
	);
};

export default VerifyEmail;
