import { avatar } from "../../../../assets/images/imageComponents";
import ButtonComponent from "../../../../shared/components/buttonComponent";
import FloatInputField from "../../../../shared/components/floatInputField";
import Profile from "../profile";

const Help = () => {
  return (
    <>
      <Profile>
        <div className="col-lg-10 col-xl-8 p-lg-4 general_section">
          <div className="row">
            <div className="col-12 mb-4">
              <h4>Get in touch</h4>
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <h6>Fullname</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "",
                  type: "text",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center">
              <h6>Email Address</h6>
            </div>
            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "",
                  type: "text",
                  style: "w-100 input_style border rounded ",
                }}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-4 d-flex">
              <h6>Message</h6>
            </div>

            <div className="col-md-8">
              <FloatInputField
                props={{
                  label: "",
                  type: "textarea",
                  style: "w-100 input_textarea",
                }}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-4 d-flex align-items-center"></div>
            <div className="col-md-8">
              <ButtonComponent
                props={{
                  anchor: "Send a message",
                  style: "onboarding_btn w-100 text-uppercase f-14",
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <h4>Frequently Asked Questions</h4>
          <div className="mt-4">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    How long does it take to complete a transaction ?
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-expanded="false"
                    aria-controls="panelsStayOpen-collapseTwo"
                  >
                    How long does refund take to arrive on failed transactions ?
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Exercitationem accusantium repudiandae dicta suscipit enim
                    quam labore esse, officia, sunt ratione doloremque id iste
                    ducimus fuga illum error? Totam, aliquam veniam.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Profile>
    </>
  );
};

export default Help;
