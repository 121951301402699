import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import { axiosPublic } from '../../shared/helpers/axios/axios-public';
import OnboardingLayout from '../../shared/layouts/onboardingLayout';
import * as yup from 'yup';
import { toFormData } from '../../shared/helpers/authHelpers';
import { useEffect } from 'react';

const SignIn = () => {
	const { enqueueSnackbar } = useSnackbar();
	const mutation = useMutation((formData) => {
		return axiosPublic.post(`/login`, formData);
	});

	const navigate = useNavigate();
	const schema = yup.object({
		email: yup.string().required('Email is required').email('Invalid email format').max(300),
		pword: yup.string().required('Password is required'),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const handleLogin = async (payload) => {
		try {
			const res = await mutation.mutateAsync(toFormData(payload));
			let data = res.data;

			enqueueSnackbar(data?.msg, {
				variant: data?.status ? 'success' : 'error',
			});

			if (data?.status) {
				// console.log(data?.data, 'token');
				const tokens = {
					refresh_token: data?.data?.refresh_token,
					access_token: data?.data?.access_token,
				};
				localStorage.setItem('swiftwaze_tokens', JSON.stringify(tokens));
				navigate('/dashboard');
			}

			if (data?.data?.is_verified === false) {
				navigate('/verify-email', { state: { email: payload.email } });
			}
		} catch (error) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
		}
	};

	useEffect(() => {
		if (localStorage.getItem('swiftwaze_tokens')) {
			navigate('/dashboard');
		}
	}, []);

	return (
		<OnboardingLayout>
			<div className="onboarding_scroll_y">
				<div className="text-center">
					<h3 className="fw-bold">Welcome Back</h3>
					<p className="home_text text-muted">Login to your account</p>
				</div>
				<form onSubmit={handleSubmit(handleLogin)}>
					<div className="col-12">
						<label htmlFor="email">Email</label>
						<input type="email" className="w-100 onboarding_field shadow-sm" {...register('email')} />
						<p className="mt-2 text-danger f-12">{errors.email?.message}</p>
					</div>
					<div className="col-12">
						<label htmlFor="pword">Password</label>
						<input type="password" className="w-100 onboarding_field shadow-sm" {...register('pword')} />
						<p className="mt-2 text-danger f-12">{errors.pword?.message}</p>
					</div>
					<button className="primary_btn mt-4">
						{mutation.isLoading && (
							<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-3" />
						)}
						Sign In
					</button>
				</form>
				<div className="d-flex justify-content-center mt-4">
					<NavLink to="/forgot-password">Forgot Password</NavLink>
				</div>
			</div>
		</OnboardingLayout>
	);
};

export default SignIn;
