import { Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUser, setToken } from '../redux/authSlice';

const PrivateRoutes = () => {
	const token = localStorage.getItem('swiftwaze_tokens');
	const dispatch = useDispatch();

	useEffect(() => {
		if (token) {
			dispatch(fetchUser());
			dispatch(setToken(token));
		}
	}, [token]);

	return token ? <Outlet /> : <Navigate to="sign-in" />;
};

export default PrivateRoutes;
