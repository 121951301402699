const StatsComponent = () => {
  return (
    <>
      <div className="stats_section d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 flex-column col-lg-3 d-flex align-items-center justify-content-center">
              <h1 className="stats_header mb-0">3</h1>
              <p className="stats_text mb-0">Core services</p>
            </div>
            <div className="col-md-6 mb-3 flex-column col-lg-3 d-flex align-items-center justify-content-center">
              <h1 className="stats_header mb-0">2</h1>
              <p className="stats_text mb-0">Currencies</p>
            </div>
            <div className="col-md-6 mb-3 flex-column col-lg-3 d-flex align-items-center justify-content-center">
              <h1 className="stats_header mb-0">4+</h1>
              <p className="stats_text mb-0">Collection Methods</p>
            </div>
            <div className="col-md-6 mb-3 flex-column col-lg-3 d-flex align-items-center justify-content-center">
              <h1 className="stats_header mb-0">20+</h1>
              <p className="stats_text mb-0">Financial Partners</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatsComponent;
