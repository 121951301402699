import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import { Input, Select } from 'antd';
import OnboardingLayout from '../../shared/layouts/onboardingLayout';
import * as yup from 'yup';
import { toFormData } from '../../shared/helpers/authHelpers';
import axios from 'axios';
import { axiosPublic } from '../../shared/helpers/axios/axios-public';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const schema = yup.object({
	fname: yup.string().required('First Name is required').min(3).max(50),
	lname: yup.string().required('Last Name is required').min(3).max(50),
	phone: yup
		.number()
		.typeError("That doesn't look like a phone number")
		.positive("A phone number can't start with a minus")
		.integer("A phone number can't include a decimal point")
		.min(11)
		.required('A phone number is required'),
	country_id: yup.string().required('Country is required'),
	email: yup.string().email('Invalid email format').max(300).required('Email is required'),
	pword: yup
		.string()
		.required('Password is required')
		.min(8, 'Minimum of 8 characters')
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
			'Use 8 or more characters with a mix of uppercase/lowercase letters, numbers & symbols'
		),
	referrer_code: yup.string(),
});

const SignUp = () => {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const mutation = useMutation((formData) => {
		return axiosPublic.post(`/register`, formData);
	});

	// Get list of countries
	const { data: countryList } = useQuery('country-list', () => {
		return axiosPublic.get(`/common/countries`);
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data) => {
		// console.log(data, 'submit');
		try {
			const res = await mutation.mutateAsync(toFormData(data));
			// console.log(res, 'res');
			enqueueSnackbar(res.data.msg, {
				variant: res.data.status ? 'success' : 'error',
			});
			res.data.status && navigate('/verify-email', { state: { email: data?.email } });
		} catch (error) {
			// console.log(error, 'error');
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
		}
	};

	return (
		<OnboardingLayout>
			<div className="onboarding_scroll_y">
				<div className="text-center">
					<h3 className="fw-bold">Welcome Back</h3>
					<p className="home_text text-muted">Sign up for an account</p>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="col-12">
						<label htmlFor="email">Email</label>
						<input type="email" className="w-100 onboarding_field shadow-sm" {...register('email')} />
						<p className="text-danger f-10 mt-2">{errors.email?.message}</p>
					</div>
					<div className="col-12">
						<label htmlFor="fname">First Name</label>
						<input type="text" className="w-100 onboarding_field shadow-sm" {...register('fname')} />
						<p className="text-danger f-10 mt-2">{errors.fname?.message}</p>
					</div>
					<div className="col-12">
						<label htmlFor="lname">Last Name</label>
						<input type="text" className="w-100 onboarding_field shadow-sm" {...register('lname')} />
						<p className="text-danger f-10 mt-2">{errors.lname?.message}</p>
					</div>
					<div className="col-12">
						<label htmlFor="country_id">Country</label>
						<Controller
							name="country_id"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<Select
									{...field}
									showSearch
									bordered={false}
									optionFilterProp="children"
									filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
									className="w-100 onboarding_field shadow-sm"
									options={countryList?.data?.data?.map((country) => {
										return { label: country?.name, value: country?.id };
									})}
								/>
							)}
						/>
						<p className="text-danger f-10 mt-2">{errors.phone?.country_id}</p>
					</div>
					<div className="col-12">
						<label htmlFor="phone">Phone Number</label>
						<Controller
							name="phone"
							control={control}
							rules={{ required: true }}
							render={({ field }) => (
								<PhoneInput
									{...field}
									inputExtraProps={{
										required: true,
										min: 11,
									}}
									country={'ng'}
									placeholder=""
									countryCodeEditable={false}
									enableSearch={true}
									disableSearchIcon={true}
									// buttonClass="w-20px"
									inputClass={`w-100 onboarding_field phone_input shadow-sm`}
									// inputStyle={{ width: '100%' }}
								/>
							)}
						/>
						<p className="text-danger f-10 mt-2">{errors.phone?.message}</p>
					</div>
					<div className="col-12">
						<label htmlFor="pword">Password</label>
						<input type="password" className="w-100 onboarding_field shadow-sm" {...register('pword')} />
						<p className="text-danger f-10 mt-2">{errors.pword?.message}</p>
					</div>
					<div className="col-12">
						<label htmlFor="referrer_code">Referral Code</label>
						<input type="text" className="w-100 onboarding_field shadow-sm" {...register('referrer_code')} />
						<p className="text-danger f-10 mt-2">{errors.referrer_code?.message}</p>
					</div>
					<button type="submit" className="primary_btn mt-4">
						{mutation.isLoading && (
							<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-3" />
						)}
						Sign Up
					</button>
				</form>
			</div>
		</OnboardingLayout>
	);
};

export default SignUp;
