import { Skeleton } from 'antd';
import ButtonComponent from '../../../shared/components/buttonComponent';
import { useSelector } from 'react-redux';
import moment from 'moment';

const TransactionDetails = () => {
	const { transaction, loadDetails } = useSelector((state) => state.transaction);

	return (
		<div className="container drawer_container">
			<div className="col-12">
				<div className="mb-4">
					<div>
						<h3 className="drawer_header">Transaction Details</h3>
						{/* <p className="drawer_subheader">To make a transfer provide the detials below</p> */}
					</div>
					{loadDetails ? (
						<div className="mt-5">
							<Skeleton active />
						</div>
					) : (
						<>
							<div className="d-flex align-items-center justify-content-between mt-5">
								<h4>- {transaction?.amount}</h4>
								<p className="text-success f-12">Success</p>
							</div>
							<div className="d-flex justify-content-between mt-4">
								<h4 className="text-muted f-12">Recipient</h4>
								<div className="text-end ms-5">
									<h4 className="f-12">{transaction?.receiver}</h4>
									<p className="f-9 text-muted mb-0">Guranteed Trust Bank</p>
								</div>
							</div>
							<div className="d-flex justify-content-between mt-4">
								<h4 className="text-muted f-12">Type</h4>
								<h4 className="f-12">{transaction?.type}</h4>
							</div>
							<div className="d-flex justify-content-between mt-4">
								<h4 className="text-muted f-12">Reference</h4>
								<h4 className="f-12">{transaction?.reference}</h4>
							</div>
							<div className="d-flex justify-content-between mt-4">
								<h4 className="text-muted f-12">Paid On</h4>
								<h4 className="f-12">
									{moment(transaction?.trans_date).format('ddd mm, yyyy')} at{' '}
									{moment(transaction?.trans_date).format('HH:mm a')}
								</h4>
							</div>
							<div className="mt-4">
								<h4 className="text-muted f-12">Description</h4>
								<textarea
									value={transaction?.description}
									className="w-100 input_style border rounded"
									cols="30"
									rows="10"
								/>
							</div>

							<div className="mt-4">
								<ButtonComponent
									props={{
										anchor: 'Download Receipt',
										style: 'onboarding_btn w-100 text-uppercase f-12',
									}}
								/>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default TransactionDetails;
