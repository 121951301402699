import React from 'react';
import OtpInput from 'react-otp-input';
import ButtonComponent from '../buttonComponent';
import LeftArrow from '../../../assets/icons/left_arrow.svg';

const TransactionPin = ({ setOtp, handleTransaction, otp, setScreen, loading, buttonText }) => {
	const handleOTP = (otp) => setOtp(otp);
	return (
		<div>
			<div className="d-flex flex-col gap-2 align-items-center">
				<img src={LeftArrow} alt="back" />
				<span className="cursor text_primary text-underline" onClick={() => setScreen(1)}>
					Go Back
				</span>
			</div>

			<h3 className="drawer_header">Transaction Pin</h3>
			<p className="drawer_subheader">Provide transaction to fund wallet from card</p>

			<form className="mt-5" onSubmit={handleTransaction}>
				<div className="row">
					<div className="col-12 mt-5">
						<label className="text_secondary mb-2">Enter your 4 digit pin</label>
						<div className="mb-5 col-12 d-flex justify-content-center gap-5">
							<OtpInput
								inputType="password"
								value={otp}
								onChange={handleOTP}
								numInputs={4}
								inputStyle="transaction_otp otp_style"
								renderInput={(props) => <input {...props} />}
							/>
						</div>
						<div className="col-12">
							<ButtonComponent
								props={{
									anchor: buttonText || 'proceed',
									style: 'onboarding_btn w-100 text-uppercase',
									// handleClick: handleTransaction,
									disabled: !otp || otp?.length < 4,
									isLoading: loading,
								}}
							/>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default TransactionPin;
