import { Spinner } from 'react-bootstrap';
const ButtonComponent = ({ props }) => {
	return (
		<button
			disabled={props.isLoading || props.disabled}
			type="submit"
			className={props.style && props.style}
			onClick={props.handleClick && props.handleClick}
		>
			{props.isLoading && (
				<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" className="me-3" />
			)}
			{props.anchor}
		</button>
	);
};

export default ButtonComponent;
