const SavedCard = () => {
  return (
    <>
      <div className="saved_card d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="dot"></div>
          <div className="ms-3">
            <h5 className="mb-0">**** **** 9793</h5>
            <p className="mb-0">CARD NUMBER</p>
          </div>
        </div>
        <div className="">
          <h5 className="mb-0">01/24</h5>
          <p className="mb-0">EXPIRY DATE</p>
        </div>
      </div>
    </>
  );
};

export default SavedCard;
