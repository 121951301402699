import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosPrivate } from '../helpers/axios/axios-private';

export const fetchTopUpList = createAsyncThunk('topup_list', async (data) => {
	const response = await axiosPrivate.post(`/transactions`, data);
	return response?.data;
});

export const fetchSendMoneyList = createAsyncThunk('money_list', async (data) => {
	const response = await axiosPrivate.post(`/transactions`, data);
	return response?.data?.data;
});

export const fetchTransaction = createAsyncThunk('transact_details', async (data) => {
	console.log(data, 'type');
	const response = await axiosPrivate.get(`/transactions?id=${data.id}&transaction_type=${data.type}`);
	return response?.data?.data;
});

const transactionSlice = createSlice({
	name: 'transactions',
	initialState: {
		topups: [],
		transfers: [],
		transaction: null,
		isVisible: false,
		loadDetails: false,
		loadList: false,
	},
	reducers: {
		setVisibility: (state, action) => {
			state.isVisible = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchTransaction.pending, (state) => {
				state.loadDetails = true;
			})
			.addCase(fetchTransaction.fulfilled, (state, { payload }) => {
				state.transaction = payload;
				state.loadDetails = false;
			})
			.addCase(fetchTopUpList.pending, (state) => {
				state.loadList = true;
			})
			.addCase(fetchTopUpList.fulfilled, (state, { payload }) => {
				state.topups = payload;
				state.loadList = false;
			})
			.addCase(fetchSendMoneyList.pending, (state) => {
				state.loadList = true;
			})
			.addCase(fetchSendMoneyList.fulfilled, (state, { payload }) => {
				state.transfers = payload;
				state.loadList = false;
			});
	},
});

export const { setVisibility } = transactionSlice.actions;
export default transactionSlice.reducer;
