import { Select } from "antd";
import ButtonComponent from "../../../../shared/components/buttonComponent";
import FloatInputField from "../../../../shared/components/floatInputField";

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const { Option } = Select;

const RequestPayout = () => {
  return (
    <div className="container">
      <h3 className="drawer_header">Request Payout</h3>
      <p className="drawer_subheader">
        To make a transfer provide the detials below
      </p>
      <div className="mt-5">
        <Select defaultValue="lucy" onChange={handleChange}>
          <Option value="jack">NGN Wallet Balance</Option>
          <Option value="lucy">Dollar Wallet Balance</Option>
        </Select>
        <h3 className="mt-2 balance_header">₦ 3,041,730</h3>
        <form action="" className="row">
          <div className="col-12 mt-4">
            <FloatInputField
              props={{
                label: "Amount",
                type: "number",
                style: "w-100 input_style rounded border",
              }}
            />
          </div>
          <div className="col-12 mt-4">
            <FloatInputField
              props={{
                label: "Email/Username",
                type: "text",
                style: "w-100 input_style rounded border",
              }}
            />
          </div>
          <div className="col-12 mt-4">
            <ButtonComponent
              props={{
                anchor: "Send Request",
                style: "onboarding_btn w-100 text-uppercase",
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default RequestPayout;
