import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logo } from '../../../assets/images/imageComponents';
import DashboardUserProfile from '../../components/userProfile/dashboard/dashboardUserProfile';
import WalletUserProfile from '../../components/userProfile/wallet/walletUserProfile';
import LeftSide from './leftSide';
import SetPinModal from '../../../pages/app/profile/security/SetPinModal';
import DashboardRightPanel from '../../components/dashboard/DashboardRightPanel';

const DashboardLayout = ({ children }) => {
	const location = useLocation();
	const { user } = useSelector((state) => state.auth);
	const [menu, setVisible] = useState(false);
	const [userProfile, setUserProfile] = useState(false);
	const [show, setShow] = useState(false);

	const openMenu = () => {
		setVisible(true);
	};

	const closeMenu = () => {
		setVisible(false);
	};

	const openUserProfile = () => {
		setUserProfile(true);
	};

	const closeUserProfile = () => {
		setUserProfile(false);
	};
	useEffect(() => {
		console.log(user?.pin_set);
		if (user?.pin_set === false) {
			setShow(true);
		}
	}, [user]);

	return (
		<>
			<div className="wrapper">
				<div className="d-flex d-lg-none container-fluid py-3 align-items-center justify-content-between">
					<button className="bg-transparent d-flex py-1 align-items-center border" onClick={openMenu}>
						<span className="material-icons">menu</span>
					</button>
					<img src={logo} height="40px" alt="" />
					<button className="bg-transparent d-flex py-1 align-items-center border" onClick={openUserProfile}>
						<span className="material-icons">apps</span>
					</button>
				</div>
				<div className="d-flex vh-100 vw-100">
					<div className="xs_hide sm_hide md_hide">
						<LeftSide />
					</div>
					<div className="content scroll_y">{children}</div>
				</div>

				{/* Menu Drawer */}
				<Drawer closable={false} placement="left" width={180} onClose={closeMenu} visible={menu}>
					<LeftSide />
				</Drawer>

				{/* User Profile Drawer */}
				{location.pathname !== '/profile' && (
					<Drawer closable={false} placement="right" width={300} onClose={closeUserProfile} visible={userProfile}>
						<div className="scroll_y vh-100">
							<DashboardRightPanel>
								{location.pathname === '/wallets' && <WalletUserProfile />}
								{location.pathname === '/dashboard' && <DashboardUserProfile />}
							</DashboardRightPanel>
						</div>
					</Drawer>
				)}
			</div>
			<SetPinModal show={show} setShow={setShow} />
		</>
	);
};

export default DashboardLayout;
