import { avatar, card } from "../../../../assets/images/imageComponents";

const DashboardUserProfile = () => {
  return (
    <div className="col-12">
      <div className="card_bg rounded my-4 mt-5">
        <div className="p-4">
          <h6 className="wallet_header">Wallet Balance</h6>
          <h3 className="wallet_text">₦ 4,509,063</h3>
        </div>
      </div>
      <div className="card_bg rounded">
        <div className="p-4">
          <h6 className="wallet_header">Wallet Balance</h6>
          <h3 className="wallet_text">$ 5,063</h3>
        </div>
      </div>
      <div className="my-5">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="beneficiary">Beneficiaries</h5>
          <h6 className="see_all">SEE ALL</h6>
        </div>
        <div className="d-flex align-items-center scroll_x justify-content-between mt-3">
          {[1, 2, 3, 4].map((beneficiary, i) => (
            <div className="text-center me-3" key={i}>
              <img width={60} height={60} className="rounded-circle" src={avatar} alt="" />
              <p className="mb-0 new_beneficiary_text">David</p>
            </div>
          ))}
        </div>
      </div>
      <div className="my-5">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="beneficiary">My Cards</h5>
          <h6 className="see_all">SEE ALL</h6>
        </div>
        <div className="text-center">
          <img className="w-100" src={card} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DashboardUserProfile;
